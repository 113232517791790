import { BranchModel } from '@/models/branch.model'
import { StorageModel } from '@/models/storage.model'
import { IntegrationModel } from '@/models/integration.model'

export class DeviceModel {
  constructor(request = {}) {
    this.active = request?.active
    this.branch = new BranchModel(request?.branch)
    this.storage = new StorageModel(request?.storage)
    this.integration = new IntegrationModel(request?.integration)
    this.id = request?.id
    this.name = request?.name
    this.ip = request?.ip
    this.number = request?.numbers
    this.list = request?.list || { id: '' }
    this.type = request?.type
    this.tags = request?.tags
    this.PLUs = request?.PLUs
  }

  get input() {
    return {
      branch: this.branch.id,
      storage: this.storage.id,
      integration: this.integration?.id,
      id: this.id,
      ip: this.ip,
      name: this.name,
      type: this.type,
      tags: this.tags,
      number: +this.number,
      list: this.list.id
    }
  }
}
