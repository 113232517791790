var render = function render(){
  var _vm$filtered_items;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "plu_product_list_modal",
      "hide-header": "",
      "centered": "",
      "size": "xl",
      "hide-footer": "",
      "body-class": "d-flex flex-column"
    },
    on: {
      "hidden": _vm.close
    }
  }, [_c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.selected_categories
    },
    on: {
      "add-categories": _vm.addCategories
    }
  }), _c('div', {
    staticClass: "plu_product_header"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('h5', {}, [_vm._v("Товары в списке")]), _c('h5', {
    staticClass: "list_name"
  }, [_vm._v(_vm._s(_vm.list_name))])]), _c('e-button', {
    staticClass: "close",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/modal_close.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "buttons-container"
  }, [_c('div', {
    staticClass: "filters-block"
  }, [_c('div', [_c('p', {
    staticClass: "label-category"
  }, [_vm._v("Категория")]), _c('div', {
    staticClass: "category-filter",
    on: {
      "!click": function ($event) {
        return _vm.showLoadModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "category"
  }, [_c('span', {
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.categoriesCount))]), _c('img', {
    staticStyle: {
      "transform": "rotate(180deg)"
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg",
      "alt": "arrow-up"
    }
  })])])]), _c('e-select', {
    staticClass: "supplier_select",
    attrs: {
      "select_name": "suppliers",
      "options": _vm.suppliers_list,
      "multiple": "",
      "placeholder": "Все поставщики",
      "label": "Поставщик"
    },
    model: {
      value: _vm.selected_suppliers,
      callback: function ($$v) {
        _vm.selected_suppliers = $$v;
      },
      expression: "selected_suppliers"
    }
  }), _c('div', {
    staticClass: "check"
  }, [_c('e-checkbox', {
    attrs: {
      "checked": _vm.is_today
    },
    on: {
      "click": function ($event) {
        _vm.is_today = !_vm.is_today;
      }
    }
  }), _c('p', {
    on: {
      "click": function ($event) {
        _vm.is_today = !_vm.is_today;
      }
    }
  }, [_vm._v("Измененные за сегодня")])], 1)], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('e-button', {
    staticClass: "mr-2 load-btn",
    attrs: {
      "size": "m",
      "variant": "primary"
    },
    on: {
      "click": _vm.scalesLoad
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/Reload.svg",
      "alt": "load"
    }
  }), _vm._v(" Загрузить на весы ")]), _c('e-button', {
    attrs: {
      "size": "m"
    },
    on: {
      "click": _vm.printPLU
    }
  }, [_vm._v(" Печать ")])], 1)]), _c('div', {
    staticClass: "container-plu-products"
  }, [_c('div', {
    staticClass: "table-header"
  }, [_c('e-input', {
    staticClass: "filter-search",
    attrs: {
      "search-icon": "",
      "type": "text",
      "placeholder": "Поиск товара по PLU, штрих-коду, артикулу или наименованию"
    },
    on: {
      "input": _vm.searchPlu
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('resizable-table', {
    staticClass: "plu-table",
    attrs: {
      "table_name": "plu-table",
      "default_fields": _vm.fields,
      "busy": _vm.$apollo.queries.ReadPlusByList.loading,
      "items": (_vm$filtered_items = _vm.filtered_items) !== null && _vm$filtered_items !== void 0 ? _vm$filtered_items : []
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.select_all
          },
          on: {
            "click": function ($event) {
              _vm.select_all = !_vm.select_all;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.active(item === null || item === void 0 ? void 0 : item.id)
          },
          on: {
            "click": function ($event) {
              return _vm.selectItem(item === null || item === void 0 ? void 0 : item.id);
            }
          }
        })];
      }
    }, {
      key: "body_name",
      fn: function (data) {
        var _data$item$product;

        return [_c('div', {
          staticClass: "plu-table__name-product"
        }, [_vm._v(" " + _vm._s(data.item.product.name) + " "), _c('div', {
          staticClass: "b-name article"
        }, [_vm._v(" " + _vm._s(((_data$item$product = data.item.product) === null || _data$item$product === void 0 ? void 0 : _data$item$product.articul) || 'Не указан') + " ")])])];
      }
    }, {
      key: "body_plu",
      fn: function (data) {
        return [_c('table-row-input', {
          staticStyle: {
            "max-width": "100px"
          },
          attrs: {
            "value": +data.value,
            "fixed": false
          },
          on: {
            "handle-input": function (val) {
              return _vm.updatePlu(val, data.item);
            }
          }
        })];
      }
    }, {
      key: "body_retailPrice",
      fn: function (data) {
        return [_c('div', {
          staticClass: "plu-table__retailPrice"
        }, [_vm._v(" " + _vm._s(data.item.product.retailPrice) + " ")])];
      }
    }, {
      key: "body_remainder",
      fn: function (data) {
        return [_c('div', {
          staticClass: "plu-table__product-remainders"
        }, [_vm._v(" " + _vm._s(_vm.calcRemainders(data.item.product.remainders)) + " ")])];
      }
    }, {
      key: "body_description",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.existDescription(data.item.product)) + " ")];
      }
    }, {
      key: "body_production_date",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(data.item.product)) + " ")];
      }
    }, {
      key: "body_expiration_date",
      fn: function (data) {
        var _data$item$product2;

        return [_c('table-row-input', {
          attrs: {
            "value": (_data$item$product2 = data.item.product) === null || _data$item$product2 === void 0 ? void 0 : _data$item$product2.expiration_date,
            "fixed": false
          },
          on: {
            "handle-input": function (value) {
              return _vm.updateExpirationDate(data.item.product, value);
            }
          }
        })];
      }
    }])
  }), _c('table-navbar', {
    staticStyle: {
      "position": "absolute",
      "bottom": "0",
      "left": "0",
      "right": "7px"
    },
    attrs: {
      "hide_print": "",
      "items": _vm.selected
    },
    on: {
      "remove_items": _vm.removePLUs
    }
  })], 1), _c('div', {
    staticClass: "d-flex mt-auto"
  }, [_c('product-search-all', {
    attrs: {
      "placeholder": "Добавить новый товар"
    },
    on: {
      "select_product": _vm.addProduct
    }
  })], 1), _c('pagination', {
    attrs: {
      "total": _vm.total
    },
    on: {
      "change_pagination": _vm.changePagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }