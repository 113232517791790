<template>
  <b-modal
    id="plu_product_list_modal"
    hide-header
    centered
    size="xl"
    hide-footer
    body-class="d-flex flex-column"
    @hidden="close"
  >
    <categories-load-modal
      :included_categories="selected_categories"
      @add-categories="addCategories"
    />
    <div class="plu_product_header">
      <div class="d-flex">
        <h5 class="">Товары в списке</h5>
        <h5 class="list_name">{{ list_name }}</h5>
      </div>
      <e-button
        class="close"
        @click="close"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </e-button>
    </div>
    <div class="buttons-container">
      <div class="filters-block">
        <div>
          <p class="label-category">Категория</p>
          <div
            class="category-filter"
            @click.capture="showLoadModal"
          >
            <div class="category">
              <span style="white-space: nowrap"> {{ categoriesCount }}</span>
              <img
                src="/img/icons/arrow_up.svg"
                alt="arrow-up"
                style="transform: rotate(180deg)"
              />
            </div>
          </div>
        </div>
        <e-select
          v-model="selected_suppliers"
          class="supplier_select"
          select_name="suppliers"
          :options="suppliers_list"
          multiple
          placeholder="Все поставщики"
          label="Поставщик"
        />
        <div class="check">
          <e-checkbox
            :checked="is_today"
            @click="is_today = !is_today"
          />
          <p @click="is_today = !is_today">Измененные за сегодня</p>
        </div>
      </div>
      <div class="d-flex">
        <e-button
          size="m"
          variant="primary"
          class="mr-2 load-btn"
          @click="scalesLoad"
        >
          <img
            src="/img/icons/Reload.svg"
            alt="load"
          />
          Загрузить на весы
        </e-button>
        <e-button
          size="m"
          @click="printPLU"
        >
          Печать
        </e-button>
      </div>
    </div>
    <div class="container-plu-products">
      <div class="table-header">
        <e-input
          v-model="search"
          search-icon
          type="text"
          class="filter-search"
          placeholder="Поиск товара по PLU, штрих-коду, артикулу или наименованию"
          @input="searchPlu"
        />
      </div>
      <resizable-table
        table_name="plu-table"
        class="plu-table"
        :default_fields="fields"
        :busy="$apollo.queries.ReadPlusByList.loading"
        :items="filtered_items ?? []"
      >
        <template #head_id>
          <e-checkbox
            :checked="select_all"
            @click="select_all = !select_all"
          />
        </template>
        <template #body_id="{ item }">
          <e-checkbox
            :checked="active(item?.id)"
            @click="selectItem(item?.id)"
          />
        </template>
        <template #body_name="data">
          <div class="plu-table__name-product">
            {{ data.item.product.name }}
            <div class="b-name article">
              {{ data.item.product?.articul || 'Не указан' }}
            </div>
          </div>
        </template>
        <template #body_plu="data">
          <table-row-input
            style="max-width: 100px"
            :value="+data.value"
            :fixed="false"
            @handle-input="(val) => updatePlu(val, data.item)"
          />
        </template>
        <template #body_retailPrice="data">
          <div class="plu-table__retailPrice">
            {{ data.item.product.retailPrice }}
          </div>
        </template>
        <template #body_remainder="data">
          <div class="plu-table__product-remainders">
            {{ calcRemainders(data.item.product.remainders) }}
          </div>
        </template>
        <template #body_description="data">
          {{ existDescription(data.item.product) }}
        </template>
        <template #body_production_date="data">
          {{ formatDate(data.item.product) }}
        </template>
        <template #body_expiration_date="data">
          <table-row-input
            :value="data.item.product?.expiration_date"
            :fixed="false"
            @handle-input="(value) => updateExpirationDate(data.item.product, value)"
          />
        </template>
      </resizable-table>
      <table-navbar
        style="position: absolute; bottom: 0; left: 0; right: 7px"
        hide_print
        :items="selected"
        @remove_items="removePLUs"
      />
    </div>
    <div class="d-flex mt-auto">
      <product-search-all
        placeholder="Добавить новый товар"
        @select_product="addProduct"
      />
    </div>
    <pagination
      :total="total"
      @change_pagination="changePagination"
    />
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput.vue'
  import { http } from '@/http/http'
  import Pagination from '@/views/shared/components/table/Pagination.vue'
  import TableNavbar from '@/views/shared/components/table/TableNavbar.vue'
  import ProductSearchAll from '@/views/manufacture/components/ProductSearchAll.vue'
  import { DeviceModel } from '@/models/device.model'
  import formatDate from '@/utils/formatDate'
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal'
  import { print_html } from '@/utils/print'

  export default {
    name: 'PluProductListModal',
    components: {
      CategoriesLoadModal,
      ProductSearchAll,
      TableNavbar,
      TableRowInput,
      Pagination
    },
    props: {
      list_id: {
        type: String,
        default: '',
        required: true
      },
      list_name: {
        type: String,
        default: '',
        required: true
      }
    },
    apollo: {
      ReadPlusByList: {
        notifyOnNetworkStatusChange: true,
        query: require('../gql/ReadPlusByList.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              id: this.list_id,
              branch: this.currentBranch.id,
              supplierFilter: this.selected_suppliers?.map((obj) => obj.id) ?? [],
              categoryFilter: this.selected_categories,
              changed_today: this.is_today,
              pagination: this.pagination
            }
          }
        },
        result({ data }) {
          if (!data?.ReadPlusByList) return
          this.items = data?.ReadPlusByList?.PLUs
          this.total = data?.ReadPlusByList?.total
        },
        skip() {
          return !this.list_id
        }
      },
      Entities: {
        query: require('../gql/Entities.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.suppliers_list = data?.Entities
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },
    data() {
      return {
        suppliers_list: [],
        selected_suppliers: [],
        is_today: false,
        selected_categories: [],
        select_all: false,
        items: [],
        timer: null,
        device: new DeviceModel(),
        selected: [],
        total: 0,
        pagination: {
          skip: 0,
          take: 20,
          search: ''
        },
        search: '',
        fields: [
          {
            key: 'id',
            label: '',
            checked: true
          },
          {
            key: 'plu',
            label: 'PLU',
            width: 40,
            sortable: true,
            checked: true
          },
          {
            key: 'name',
            label: 'Наименование товара',
            sortable: true,
            checked: true
          },
          {
            key: 'retailPrice',
            label: 'Розн. цена, ₽',
            width: 160,
            sortable: true,
            checked: true
          },
          {
            key: 'remainder',
            label: 'Остаток',
            checked: true
          },
          {
            key: 'description',
            label: 'Описание',
            checked: true
          },
          {
            key: 'production_date',
            label: 'Годен до',
            width: 150,
            sortable: true,
            checked: true
          },
          {
            key: 'expiration_date',
            label: 'Годен дней',
            width: 150,
            sortable: true,
            checked: true
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      filtered_items() {
        return this.items
      },
      categoriesCount() {
        if (this.selected_categories?.length) {
          return 'Выбрано ' + this.selected_categories?.length
        }
        return 'Все группы товаров'
      }
    },
    watch: {
      select_all() {
        if (this.select_all) {
          this.selected = this.items.map((obj) => obj.id)
        } else {
          this.selected = []
        }
      }
    },
    methods: {
      showLoadModal() {
        this.$bvModal.show('categories-modal')
      },
      addCategories(cats) {
        this.selected_categories = cats
      },
      selectItem(id) {
        if (this.selected.includes(id)) {
          this.selected = this.selected.filter((item) => item !== id)
        } else {
          this.selected.push(id)
        }
      },
      active(id) {
        return this.selected.includes(id)
      },
      changePagination(pagination) {
        this.pagination.skip = (pagination.current_page - 1) * pagination.take
        this.pagination.take = pagination.take
        this.$apollo.queries.ReadPlusByList.refetch()
      },
      calcRemainders(item) {
        return (item?.reduce((sum, el) => sum + el.value, 0) || 0).toFixed(2)
      },
      existDescription(item) {
        return item.productDescription?.some((el) => el.tags?.includes('scales')) ? 'Есть' : 'Нет'
      },
      async scalesLoad() {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/CreateTask.graphql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                type: 'SCALES',
                status: 'AWAITING',
                products: {
                  action: 'CREATE'
                }
              }
            }
          })
          this.$noty.show('Успешно')
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка загрузки')
        }
      },
      async printPLU() {
        try {
          const { data } = await http.get('templates/plu/list/?list=' + this.list_id)
          if (data) {
            print_html(data)
          } else {
            this.$noty.show('Ошибка печати')
          }
        } catch (e) {
          console.error(e)
          this.$noty.show('Ошибка печати')
        }
      },
      async updatePlu(val, row) {
        row.plu = val
        try {
          await this.$apollo.mutate({
            mutation: require('../../products/gql/updatePlu.graphql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                product: row.product.id,
                plu: val + '',
                list: this.list_id
              }
            }
          })
        } catch (e) {
          this.$noty.error('Ошибка сохранения')
        }
      },
      async removePLUs() {
        await this.$apollo.mutate({
          mutation: require('../gql/RemovePlus.graphql'),
          variables: {
            ids: this.selected
          }
        })
        this.selected = []
        await this.$apollo.queries.ReadPlusByList.refetch()
      },
      async addProduct(product) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../gql/CreatePlu.graphql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                list: this.list_id,
                product: product.id
              }
            }
          })
          this.$noty.show('Добавлено. Присвоен код: ' + data?.CreatePlu?.plu)
          await this.$apollo.queries.ReadPlusByList.refetch()
        } catch (error) {
          this.$noty.error(error.graphQLErrors[0]?.extensions?.ru || 'Ошибка добавления')
        }
        this.selected = []
      },
      sortChange(sort) {
        this.pagination.skip = 0
        if (sort.sortBy)
          this.pagination.order = [
            {
              key: sort.sortBy === 'name' ? 'product' : sort.sortBy,
              value: sort.sortDesc ? 'DESC' : 'ASC'
            }
          ]
        else {
          this.pagination.order = null
        }
        this.$apollo.queries.ReadPlusByList.refresh()
      },
      searchPlu() {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.pagination.search = this.search
          this.pagination.skip = 0
          this.$apollo.queries.ReadPlusByList.refresh()
        }, 500)
      },
      close() {
        this.$bvModal.hide('plu_product_list_modal')
        this.selected_categories = []
        this.is_today = false
        this.selected_suppliers = []
        this.pagination = {
          skip: 0,
          take: 20,
          search: ''
        }
        this.search = ''
        this.$emit('close')
      },
      formatDate(product) {
        const date = new Date(product.production_date)
        date.setDate(date.getDate() + (product.expiration_date || 0))
        return formatDate(date)
      },
      async updateExpirationDate(product, value) {
        await this.$apollo.mutate({
          mutation: require('../../products/gql/updateProduct.gql'),
          variables: {
            input: {
              id: product.id,
              expiration_date: value,
              branch: this.currentBranch.id
            }
          }
        })
        await this.$apollo.queries.ReadPlusByList.refetch()
      }
    }
  }
</script>
<style lang="scss" scoped>
  #plu_product_list_modal {
    @media (min-width: 576px) {
      .modal-content {
        height: calc(100% - 3.5rem);
      }
    }
  }
</style>
<style scoped lang="scss">
  .plu-table {
    border: 1px solid #e1e1e1;

    &__name-product {
      text-align: left;
    }
    &__retailPrice {
      text-align: right;
    }
    &__product-remainders {
      text-align: right;
    }
    &__product-expiration_date {
      margin-left: auto;
    }
  }

  .b-name {
    &.article {
      font-size: 12px;
      line-height: 24px;
      color: #888888;
      margin-top: 5px;
    }
  }
  ::v-deep .b-search-result {
    bottom: 115px !important;
    left: 20px !important;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #bcbcbc;
    }
  }
  .container-plu-products {
    height: 45vh;
    min-height: 45vh;
    overflow: hidden;
    margin-top: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 22px;
  }
  .b-table__navbar {
    z-index: 2;
  }
  .plu_product_header {
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    h5 {
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #313131;
      margin-right: 11px;
    }
    .list_name {
      padding-left: 11px;
      border-left: 1px solid #bcbcbc;
    }
    button {
      padding: 0;
      height: fit-content;
    }
  }
  .table-header {
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    padding: 16px;
    .input-block {
      width: 470px !important;
    }
  }
  .load-btn {
    img {
      margin-right: 14px;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filters-block {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 16px;
      .supplier_select {
        max-width: 200px;
      }
      .check {
        margin-top: 18px;
        align-items: center;
        width: 160px;
        display: flex;
        p {
          white-space: nowrap;
          margin: 0 0 0 6px;
        }
      }
    }
  }
  .category-filter {
    flex: 1;
    align-items: center;
    border: 1px solid #bcbcbc;
    border-radius: 2px;
    display: flex;
    min-width: 180px;
    max-width: 300px;
    padding: 9px 10px;
    background-color: #fff;
    .category {
      flex: 1;
      display: flex;
      justify-content: space-between;
      span {
        font-family: 'Gilroy-Regular', sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 14px !important;
        display: flex;
        align-items: center;
        color: #bcbcbc !important;
      }
    }
  }
  .label-category {
    font-size: 12px;
    margin-bottom: 2px;
    margin-left: 3px;
    color: #313131;
  }
  .b-search-product {
    width: 320px;
  }
  ::v-deep .ms__dropdown {
    min-width: 100% !important;
    width: initial !important;
    max-width: 400px !important;
  }
  ::v-deep table tr td {
    border-right: none !important;
  }
</style>
